.sign-up-message_container {
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  box-shadow: var(--default-box-shadow);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  max-height: 75vh;
  max-width: 960px;
  overflow: hidden auto;
  position: relative;
  width: 100%;

  .content_container {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    max-height: calc(75vh - 70px);
    overflow: hidden auto;
    padding: 32px;
    text-align: center;
    width: 100%;

    .image_container {
      display: flex;
      height: auto;
      justify-content: center;
      line-height: 0;
      margin: 48px 0 32px;
      padding: 0 160px;
      width: 100%;

      .svg_container {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: center;
        max-width: 200px;
        min-width: 120px;
        width: 100%;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 32px;
    }

    .children_container {
      display: flex;
      font-size: 1rem;
      font-weight: bold;
      justify-content: center;
      min-height: 98px;
      width: 100%;
    }
  }

  footer {
    align-items: center;
    background: #f2f2fd;
    box-shadow: var(--default-box-shadow);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 15px;
    width: 100%;

    .ant-btn-primary {
      border-radius: 2px;
      max-width: 240px;
      overflow-x: auto;
      width: 100%;
    }
  }
}
