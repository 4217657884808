.sign-up-card_container.ant-row {
  .form-col {
    justify-content: space-between;
    padding: 0;

    .suspended-button {
      left: 20px;
      position: absolute;
      top: 25px;
      z-index: 10;
    }

    .card_container {
      border-radius: 0px;
      box-shadow: none;
      padding: 100px 0 40px;
      width: 100%;

      .ant-card-body {
        padding: 0;
        margin: 0 auto;
        width: 590px;

        .form {
          margin-top: 32px;

          .form-group {
            column-gap: 1.875rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 590px;
            width: 590px;
          }
        }
      }

      .error-alert {
        margin-bottom: 0;
      }
    }

    .ant-btn-primary {
      border-radius: 4px;
      font-size: 1rem;
      font-weight: lighter;
    }

    .terms-text {
      color: #00000099;

      a {
        color: #3c7cc1;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1024px) {
    align-items: stretch;
    flex-direction: column;
    height: auto;
    max-width: 480px;
    width: 100%;

    .ant-col {
      flex: 0 0 auto;
      max-width: 100%;
      width: 100%;
    }

    .logo-col {
      .logo-container {
        max-width: 400px;
      }
    }

    .form-col {
      .suspended-button {
        display: none;
        pointer-events: none;
      }

      .card_container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px 48px 32px;

        .ant-card-body {
          max-width: 320px;
          width: 100%;

          .form {
            align-items: center;
            display: flex;
            flex-direction: column;

            .form-group {
              display: flex;
              flex-direction: column;
              width: 100%;
            }
          }
        }
      }

      .terms-text {
        max-width: 40ch;
      }
    }
  }

  @media (max-width: 375px) {
    .form-col {
      .card_container {
        padding: 48px 24px;
      }
    }
  }
}
