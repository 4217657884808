.sign-up_account-reproved {
  .reproach-explanation_container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0 4rem;
    width: 100%;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      overflow: auto;
      padding: 0;
    }

    .reasons_container {
      display: flex;
      flex-flow: column nowrap;
      padding: 1rem;
      text-align: left;

      ul:not(.ant-skeleton-paragraph) {
        padding: 0 1rem;
        display: flex;
        flex-flow: column nowrap;

        li {
          font-weight: normal;
        }
      }
    }

    .description_container {
      display: flex;
      flex-flow: column nowrap;
      padding: 1rem;

      p {
        width: 100%;
        height: 100%;
        padding: 0 1rem 0 0;
        border-radius: 4px;
        text-align: justify;
        font-weight: normal;
      }
    }

    h2 {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
    }
  }
}
