.hero_container {
  padding: 60px;
  min-height: 100vh;

  background-image: url('../../../assets/images/main-bg.jpg');

  background-image: url('../../../assets/images/main-bg.webp');

  background-image: image-set(
    '../../../assets/images/main-bg.jpg' 1x,
    '../../../assets/images/main-bg@2x.jpg' 2x
  );

  background-image: image-set(
    '../../../assets/images/main-bg.webp' 1x,
    '../../../assets/images/main-bg@2x.webp' 2x
  );

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }
}
