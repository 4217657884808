div.login-card_container {
  .logo-col {
    width: 480px;
  }

  .form-col {
    padding: 40px 100px;
    width: 560px;

    .login-card {
      border-radius: 12px;
      box-shadow: none;
      padding: 12px;
      width: 360px;

      .form {
        margin-top: 32px;

        > div:not(:last-child) {
          margin-bottom: 24px;
        }

        span {
          font-size: 0.8125rem;
          line-height: 0.8125rem;
        }

        button > span {
          font-size: inherit;
          line-height: inherit;
        }

        .keep-connected .ant-form-item {
          margin-bottom: 0;
        }

        .custom-link {
          color: var(--default-text-color);
          font-size: 0.8125rem;
          font-weight: bold;
          line-height: 0.8125rem;
          text-decoration: underline;
        }
      }

      .error-alert {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    align-items: stretch;
    flex-direction: column;
    height: auto;

    .ant-col {
      flex: 0 0 auto;
      max-width: 100%;
      width: 100%;
    }

    .form-col {
      padding: 12px;

      .login-card {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 375px) {
    .form-col {
      .login-card {
        padding: 12px;
      }
    }
  }
}
