.terms-of-use_container {
  .form-col {
    justify-content: space-between;
    padding: 0;
    width: 880px;

    .terms-of-use_card {
      border-radius: 0px;
      padding: 65px 50px 40px;
      width: 100%;
      height: 100%;

      .ant-card-body {
        padding: 0;
        width: 100%;
        height: 100%;

        .form {
          margin-top: 1.25rem;
          width: 100%;

          .terms-document {
            width: 100%;
            border: 1px solid #bbbbbb;
            border-radius: 4px;
            box-shadow: var(--default-box-shadow);
            height: 250px;
          }

          .accept-terms {
            margin: 24px 0 0;

            .ant-form-item-control-input-content {
              flex: none;
            }

            span {
              color: #00000099;
              font-size: 1rem;
              word-spacing: 1px;
            }
          }
        }
      }

      .error-alert {
        margin-bottom: 0;
      }
    }

    footer {
      padding: 16px 32px;
    }

    .ant-btn-primary {
      border-radius: 4px;
      font-size: 1rem;
      font-weight: lighter;
    }
  }

  @media (max-width: 1024px) {
    align-items: stretch;
    flex-direction: column;
    height: auto;
    max-width: 480px;
    width: 100%;

    .ant-col {
      flex: 0 0 auto;
      max-width: 100%;
      width: 100%;
    }

    .logo-col {
      .logo-container {
        max-width: 400px;
      }
    }

    .form-col {
      .terms-of-use_card {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px 48px 32px;
        width: 100%;

        .ant-card-body {
          width: 100%;

          .form {
            align-items: center;
            display: flex;
            flex-direction: column;

            .terms-document {
              height: 500px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .form-col {
      .terms-of-use_card {
        padding: 48px 24px;
      }
    }
  }
}
