.hire-quotation-table {
  .no-vehicle {
    color: red;
  }

  .disabled-row {
    .ant-table-cell {
      color: rgba(0, 0, 0, 0.35);
      background-color: rgba(0, 0, 0, 0.05);
      pointer-events: none;

      .no-vehicle {
        color: inherit;
      }
    }
  }
}
