.title-header_container {
  display: flex;
  flex-direction: column;

  &.centered {
    align-items: center;
    text-align: center;
  }

  &.decorated {
    border-left: 6px solid #3e7abf;
    padding-left: 4px;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .subtitle {
    font-size: 0.8125rem;
    line-height: 0.8125rem;
    margin: 0;
  }
}
