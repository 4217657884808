div.recover-password-card_container {
  .form-col {
    justify-content: space-between;
    padding: 0;
    width: 560px;

    .recover-password-card {
      border-radius: 0px;
      padding: 100px 0 40px;
      width: 100%;

      .ant-card-body {
        padding: 0;
        margin: 0 auto;
        width: 360px;

        .form {
          margin-top: 32px;

          .form-group {
            column-gap: 1.875rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 360px;
            width: 360px;
          }
        }
      }

      .error-alert {
        margin-bottom: 0;
      }
    }

    .ant-btn-primary {
      border-radius: 4px;
      font-size: 1rem;
      font-weight: lighter;
    }
  }

  @media (max-width: 1024px) {
    align-items: stretch;
    flex-direction: column;
    height: auto;
    max-width: 480px;
    width: 100%;

    .ant-col {
      flex: 0 0 auto;
      max-width: 100%;
      width: 100%;
    }

    .logo-col {
      .logo-container {
        max-width: 400px;
      }
    }

    .form-col {
      width: 100%;

      .recover-password-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 100px 48px 32px;
        max-width: 100%;

        .ant-card-body {
          max-width: 100%;
          width: 100%;

          .form {
            align-items: center;
            display: flex;
            flex-direction: column;

            .ant-form-item {
              width: 100%;
            }

            .form-group {
              display: flex;
              flex-direction: column;
              width: 100%;

              .ant-form-item-explain-connected {
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .form-col {
      .recover-password-card {
        padding: 100px 24px 48px;
      }
    }
  }
}
