.subheader {
  padding: 24px;
  font-size: 24px;
  font-weight: bold;
  background-color: white;
}

.content_card-row {
  width: 100%;
  padding: 30px;

  .container_card {
    width: 100%;

    .ant-card-body {
      padding: 30px;
    }

    .container_card-title {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    }

    .container_card-subtitle {
      font-size: 12px;
    }

    .container_card-content {
      margin-top: 26px;
    }
  }

  @media (max-width: 400px) {
    padding: 24px;

    .container_card .ant-card-body {
      padding: 24px;
    }
  }
}

.loader {
  position: fixed;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader-child {
  pointer-events: none;
}
