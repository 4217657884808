.hero-card_container {
  footer {
    align-items: center;
    background: #f2f2fd;
    box-shadow: var(--default-box-shadow);
    column-gap: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 15px 80px;
    width: 100%;

    .ant-btn-primary {
      border-radius: 2px;
      overflow: hidden;
      width: 240px;
    }

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      padding: 15px 48px;
      row-gap: 15px;

      .ant-btn-primary {
        max-width: 320px;
        width: 100%;
      }
    }

    @media (max-width: 375px) {
      padding: 15px 32px;
    }
  }
}
