.support-help-contact__container {
  position: absolute;
  right: 24px;
  top: 18px;
  z-index: 10;

  > button {
    color: gray;
    font-size: 32px;
    height: auto;
    line-height: 32px;
    padding: 4px;
  }
}
