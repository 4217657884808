:root {
  --default-text-color: #363a45;
  --default-background-gradient: transparent
    linear-gradient(180deg, #3e7abf 0%, #00b0ff 100%) 0% 0% no-repeat
    padding-box;

  --default-box-shadow: 0px 3px 6px #00000029;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .error-alert {
    overflow: auto;
    margin: 24px 0px;
    border-radius: 5px;

    .ant-alert-message {
      font-size: 15px;
    }
    .ant-alert-description {
      font-size: 13px;
    }
  }

  .mapbox__map-container {
    height: 728px;
    max-width: 100%;
    width: 100vw;

    @media screen and (max-width: 992px) {
      max-height: 60vh;
    }
  }

  .ant-descriptions-item-label {
    font-weight: 500;
  }

  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-loading-icon {
  margin-right: 10px;
}

.ant-table-wrapper.ant-table-wrapper-responsive {
  .ant-table {
    .ant-table-container {
      overflow: auto;
    }
  }
}

.ant-page-header-compact {
  @media screen and (max-width: 768px) {
    .ant-page-header-heading-left {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.ant-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin: 0;
    }
  }
}
