div.company-form_container {
  display: flex;
  flex-flow: row nowrap;

  .company-form_card {
    box-shadow: none;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .form-col {
    max-width: 932px;
    padding: 0;
    width: 932px;

    @media (max-width: 1024px) {
      width: 100%;
    }

    .ant-card {
      margin: 3rem 0;
      overflow: auto;
      width: 100%;

      @media (max-width: 1024px) {
        margin: 3rem 0 1rem;
      }

      .ant-card-body {
        padding: 0 3rem;

        @media (max-width: 375px) {
          padding: 0 2rem;
        }

        .title-header_container {
          margin-bottom: 1.5rem;
        }

        .ant-form {
          .form-section_container {
            border-top: 1px solid black;
            margin-right: 20px;
            padding: 1rem 0 0;

            @media (max-width: 1024px) {
              margin: 0;
            }

            h2 {
              font-size: 1rem;
              font-weight: bold;
              margin-bottom: 1rem;
              width: 100%;
            }

            .form-section_inputs {
              display: flex;
              flex-flow: row wrap;

              > .ant-row {
                column-gap: 40px;
                width: 100%;

                .ant-form-item {
                  max-width: 240px;
                  width: 100%;

                  @media (max-width: 1024px) {
                    max-width: 100%;
                  }

                  .ant-form-item-label,
                  .ant-form-item-control {
                    flex: 0 0 auto;
                  }

                  .ant-form-item-explain-connected {
                    margin-bottom: 1.5rem;
                  }
                }

                .ant-checkbox-wrapper {
                  align-items: center;
                  max-width: 240px;
                  width: 100%;

                  .ant-checkbox {
                    top: 0;
                  }
                }
              }

              .carrier-policies-upload_container {
                column-gap: 0;
                gap: 0;
                margin: 0 -20px 24px;

                .ant-form-item {
                  max-width: 100%;

                  .ant-picker {
                    width: 100%;
                  }
                }

                & > .ant-col {
                  padding: 0 20px;
                }

                .dragger_container {
                  max-width: 100%;
                  margin-bottom: 0;

                  .ant-form-item-explain-connected {
                    margin-bottom: 0;
                  }
                }

                .ant-form-item-has-error {
                  .ant-upload-drag {
                    border-color: #ff4d4f;
                  }
                }

                @media (max-width: 1024px) {
                  margin: 0 0 24px;

                  & > .ant-col {
                    padding: 0;
                    margin-bottom: 24px;
                  }

                  .ant-picker {
                    width: 100%;
                  }

                  .ant-upload-list-item-card-actions {
                    & > button {
                      opacity: 1;
                    }
                  }
                }
              }

              .upload_container {
                margin: 0 auto 1.5rem;
              }
            }

            .ant-upload-list-picture {
              .ant-upload-list-item-progress {
                padding-right: 32px;
              }

              .ant-upload-list-item-name {
                color: #00000073;
                cursor: pointer;
                margin-right: 24px;
                padding-right: 0;

                &:hover {
                  color: #363a45;
                }
              }
            }

            .ant-upload-list-item-card-actions.picture
              .ant-upload-list-item-card-actions-btn {
              z-index: 2;
            }
          }
        }
      }
    }
  }
}

.create-mother-company_modal {
  .ant-modal-body {
    padding: 2.5rem 2.5rem 1rem;

    .ant-form {
      .form-section_container {
        h2 {
          border-bottom: 1px solid black;
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          width: 100%;
        }

        .form-section_inputs {
          display: flex;
          flex-flow: row wrap;

          .ant-row {
            column-gap: 40px;
            width: 100%;

            .ant-form-item {
              max-width: 240px;
              width: 100%;

              @media (max-width: 1024px) {
                max-width: 100%;
              }

              .ant-form-item-label,
              .ant-form-item-control {
                flex: 0 0 auto;
              }

              .ant-form-item-explain-connected {
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 0.75rem 2.5rem;

    .ant-btn.ant-btn-primary.btn-success {
      margin-left: 1rem;
    }
  }
}
